import React from "react";
import { graphql } from "gatsby";
import Builder from "../components/global/builder";

const Project = ({
  data: {
    wpProject: {
      title,
      page,
      sections: { sections },
    },
    wp: {
      themeSettings: { themeSettings },
    },
  },
  pageContext,
  location,
}) => {
  return (
    <Builder
      title={title}
      page={page}
      sections={sections}
      themeSettings={themeSettings}
      prefix="Project"
      pageContext={pageContext}
      location={location}
    />
  );
};

export const pageQuery = graphql`
  query Project($id: String!) {
    wpProject(id: { eq: $id }) {
      title
      page {
        metaTitle
        metaDescription
        pageCssOverrides
        pageSchema
      }
      sections {
        sections {
          ... on WpProject_Sections_Sections_Slider {
            ...ProjectSlider
          }
          ... on WpProject_Sections_Sections_HiThereIntro {
            ...ProjectHiThereIntro
          }
          ... on WpProject_Sections_Sections_ServicesIntro {
            ...ProjectServicesIntro
          }
          ... on WpProject_Sections_Sections_ServicesIntroContent {
            ...ProjectServicesIntroContent
          }
          ... on WpProject_Sections_Sections_ServicesIntroServices {
            ...ProjectServicesIntroServices
          }
          ... on WpProject_Sections_Sections_OurWorkIntro {
            ...ProjectOurWorkIntro
          }
          ... on WpProject_Sections_Sections_TestimonialsIntro {
            ...ProjectTestimonialsIntro
          }
          ... on WpProject_Sections_Sections_BrandsIntro {
            ...ProjectBrandsIntro
          }
          ... on WpProject_Sections_Sections_Banner {
            ...ProjectBanner
          }
          ... on WpProject_Sections_Sections_ContentWithButton {
            ...ProjectContentWithButton
          }
          ... on WpProject_Sections_Sections_PortfolioImage {
            ...ProjectPortfolioImage
          }
          ... on WpProject_Sections_Sections_SingleQuote {
            ...ProjectSingleQuote
          }
          ... on WpProject_Sections_Sections_IntroContent {
            ...ProjectIntroContent
          }
          ... on WpProject_Sections_Sections_ServiceContent {
            ...ProjectServiceContent
          }
          ... on WpProject_Sections_Sections_RelatedServices {
            ...ProjectRelatedServices
          }
          ... on WpProject_Sections_Sections_CenteredContent {
            ...ProjectCenteredContent
          }
          ... on WpProject_Sections_Sections_SimpleServiceContent {
            ...ProjectSimpleServiceContent
          }
          ... on WpProject_Sections_Sections_History {
            ...ProjectHistory
          }
          ... on WpProject_Sections_Sections_ImageWithContent {
            ...ProjectImageWithContent
          }
          ... on WpProject_Sections_Sections_Steps {
            ...ProjectSteps
          }
          ... on WpProject_Sections_Sections_SimpleBanner {
            ...ProjectSimpleBanner
          }
          ... on WpProject_Sections_Sections_PayOnline {
            ...ProjectPayOnline
          }
          ... on WpProject_Sections_Sections_Contact {
            ...ProjectContact
          }
          ... on WpProject_Sections_Sections_Map {
            ...ProjectMap
          }
          ... on WpProject_Sections_Sections_ColumnsIntroWithButton {
            ...ProjectColumnsIntroWithButton
          }
          ... on WpProject_Sections_Sections_TeamIntro {
            ...ProjectTeamIntro
          }
          ... on WpProject_Sections_Sections_Team {
            ...ProjectTeam
          }
          ... on WpProject_Sections_Sections_FullWidthContent {
            ...ProjectFullWidthContent
          }
          ... on WpProject_Sections_Sections_LetsTalk {
            ...ProjectLetsTalk
          }
          ... on WpProject_Sections_Sections_ProjectGrid {
            ...ProjectProjectGrid
          }
          ... on WpProject_Sections_Sections_Test {
            ...ProjectTest
          }
          ... on WpProject_Sections_Sections_ReusableSections {
            ...ProjectReusableSections
          }
          ... on WpProject_Sections_Sections_ValuesAccordion {
            ...ProjectValuesAccordion
          }
          ... on WpProject_Sections_Sections_PumpkinVoting {
            ...ProjectPumpkinVoting
          }
          ... on WpProject_Sections_Sections_ContactOnboarding {
            ...ProjectContactOnboarding
          }
          ... on WpProject_Sections_Sections_ContestPoll {
            ...ProjectContestPoll
          }
          ... on WpProject_Sections_Sections_JotformContact {
            ...ProjectJotformContact
          }
        }
      }
    }
    wp {
      themeSettings {
        themeSettings {
          siteMetaTitle
          customCssOverrides
          customCssClasses
        }
      }
    }
  }
`;

export default Project;
